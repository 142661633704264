import React from 'react';
import PanelButton from './PanelButton';
import StartOverPanel from './StartOverPanel';
import UploadPanel from './UploadPanel';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';
import QuickGeoPanel from './QuickGeoPanel';
import InfoPanel from './InfoPanel';
import TroubleShootPanel from './TroubleShootPanel';
import axios from 'axios';
import { FormatResults, MapDataToPointsArray, SelectFirstCandidate } from '../../utils/tools';

const buttonHolderDiv = {
  paddingTop: '10px',
}

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadShow: false,
      quickGeoShow: false,
      startOverShow: false,
      infoShow: false,
      troubleShow: false,
      uploadBtnColor: '#25408f',
      quickGeoBtnColor: '#25408f',
      infoBtnColor: '#25408f',
      troubleShootBtnColor: '#25408f',
      startOverBtnColor: '#25408f',
      freezeSidebarBtns: false,
      geocodeLoading: false,
      geocodeError: false
    };

    this.togglePanel = this.togglePanel.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.handleHoverOff = this.handleHoverOff.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.geocode = this.geocode.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }

  togglePanel(expr) {
    if (!this.state.freezeSidebarBtns) {
      switch (expr) {
        case 'upload':
          if (!this.props.isGeocoding) {
            this.setState({
              uploadShow: !this.state.uploadShow,
              quickGeoShow: false,
              startOverShow: false,
              geocodeError: false,
              troubleShow: false
            });
          }
          break;
        case 'quickGeo':
          if (!this.props.isGeocoding) {
            this.setState({
              uploadShow: false,
              quickGeoShow: !this.state.quickGeoShow,
              startOverShow: false,
              geocodeError: false,
              troubleShow: false
            });
          }
          break;
        case 'info':
          this.setState({
            troubleShow: false,
            infoShow: !this.state.infoShow
          });
          break;
        case 'troubleShoot':
            this.setState({
              infoShow: false,
              troubleShow: !this.state.troubleShow
            });
            break;
        case 'startOver':
          this.setState({
            uploadShow: false,
            quickGeoShow: false,
            startOverShow: !this.state.startOverShow,
            geocodeError: false,
            troubleShow: false,
            infoShow: false,
          });
          break;
        default:
          this.setState({
            uploadShow: false,
            quickGeoShow: false,
            startOverShow: false,
            geocodeError: false,
            troubleShow: false,
            infoShow: false,
            limitError: false
          });
      }
    }
  }

  handleHover(button) {
    this.setState({
      [button + 'BtnColor']: '#0c9ed9'
    });
  }

  handleHoverOff(button) {
    this.setState({
      [button + 'BtnColor']: '#25408f'
    });
  }

  updateStatus(bool) {
    this.setState({
      freezeSidebarBtns: bool
    });
  }

  clearErrors() {
    this.setState({
      limitError: false,
      error: false
    })
  }

  async geocode(data, isQuickGeo, addressRef, cityRef, stateRef, postalRef,countryRef,isIntl) {
    this.setState({
      geocodeError: false,
      geocodeLoading: true
    });

    const addresses = MapDataToPointsArray(data, isQuickGeo, addressRef, cityRef, stateRef, postalRef,countryRef,isIntl);
    const limit = countryRef !== 'USOnly' ? process.env.REACT_APP_INTLLIMIT : process.env.REACT_APP_USLIMIT
    console.log(limit,'limit2');

    if(addresses.addresses.length>limit){
      const limitmsg =  (countryRef !== 'USOnly' ? 'international' : 'us') + ' limit of ' + limit
      this.setState({
        limitError: true,
        geocodeLoading: false,
        limit:limitmsg
      });
    }
    else {
      let newPointsArray = [];
       let method = 'batchgeocode';
   
       await axios.post(process.env.REACT_APP_GEOCODERURL + '/' + method, addresses, {headers: {'Authorization': 'Bearer ' + this.props.token }})
         .then((result) => {
           if (result.data) {
             newPointsArray = FormatResults(result.data.addresses, isQuickGeo);
             this.props.updateMainObj({ isGeocoding: true });
           } else {
             this.setState({
               geocodeError: true,
               geocodeLoading: false
             });
             this.updateStatus(false);
             this.props.updateMainObj({ isGeocoding: false });
           }
         }).catch((error) => {
           console.log(error);
           this.setState({
             geocodeError: true,
             geocodeLoading: false
           });
           this.updateStatus(false);
           this.props.updateMainObj({ isGeocoding: false });
         });
   
       newPointsArray.sort((a, b) => (a.content.id > b.content.id) ? 1 : ((b.content.id > a.content.id) ? -1 : 0));
       //add selected attribute to first candidate
       const selectedCandidatesPoints = SelectFirstCandidate(newPointsArray);
       if (newPointsArray.length === 0) {
         this.setState({
           geocodeError: true,
           geocodeLoading: false
         });
         this.updateStatus(false);
         this.props.updateMainObj({ isGeocoding: false, points: selectedCandidatesPoints });
         return false;
       } else {
         this.props.updateMainObj({ points: selectedCandidatesPoints, geocodingSuccess: true });
   
         this.setState({
           geocodeLoading: false
         });
   
         this.updateStatus(false);
         return true;
       }
    }
  }

  render() {
    return (
      <div className="sideBarDiv">
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'upload')}
            handleHoverOff={this.handleHoverOff.bind(this, 'upload')}
            handleHover={this.handleHover.bind(this, 'upload')}
          >
            <SVGIconComponent size={'18'} name={'uploadBtnIcon'} color={this.state.uploadBtnColor} />
            <div className="sideBarBtnTxt">UPLOAD</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'quickGeo')}
            handleHoverOff={this.handleHoverOff.bind(this, 'quickGeo')}
            handleHover={this.handleHover.bind(this, 'quickGeo')}
          >
            <SVGIconComponent size={'20'} name={'legendBtnIcon'} color={this.state.quickGeoBtnColor} />
            <div className="sideBarBtnTxt">QUICK GEO</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'info')}
            handleHoverOff={this.handleHoverOff.bind(this, 'info')}
            handleHover={this.handleHover.bind(this, 'info')}
          >
            <SVGIconComponent size={'22'} name={'infoBtnIcon'} color={this.state.infoBtnColor} />
            <div className="sideBarBtnTxt">INFO</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'troubleShoot')}
            handleHoverOff={this.handleHoverOff.bind(this, 'troubleShoot')}
            handleHover={this.handleHover.bind(this, 'troubleShoot')}
          >
            <SVGIconComponent size={'22'} name={'toolsBtnIcon'} color={this.state.troubleShootBtnColor} />
            <div className="sideBarBtnTxt">Troubleshoot</div>
          </PanelButton>
        </div>
        <div style={buttonHolderDiv}>
          <PanelButton
            handleClick={this.togglePanel.bind(this, 'startOver')}
            handleHoverOff={this.handleHoverOff.bind(this, 'startOver')}
            handleHover={this.handleHover.bind(this, 'startOver')}
          >
            <SVGIconComponent size={'22'} name={'startOverBtnIcon'} color={this.state.startOverBtnColor} />
            <div className="sideBarBtnTxt">START OVER</div>
          </PanelButton>
        </div>
        <div>
          {this.state.uploadShow
            ? <UploadPanel
              togglePanel={this.togglePanel}
              token={this.props.token}
              updateMainObj={this.props.updateMainObj}
              points={this.props.points}
              getIcon={this.props.getIcon}
              currentPointId={this.props.currentPointId}
              updateStatus={this.updateStatus}
              geocode={this.geocode}
              geocodeLoading={this.state.geocodeLoading}
              geocodeError={this.state.geocodeError}
              limitError={this.state.limitError}
              useIntl={this.props.useIntl}
              limit={this.state.limit}
              clearErrors={this.clearErrors}
            />
            : null
          }
        </div>
        <div>
          {this.state.quickGeoShow
            ? <QuickGeoPanel
              token={this.props.token}
              geocode={this.geocode}
              geocodeLoading={this.state.geocodeLoading}
              geocodeError={this.state.geocodeError}
              points={this.props.points}
              currentPointId={this.props.currentPointId}
              updateMainObj={this.props.updateMainObj}
              getIcon={this.props.getIcon}
            />
            : null
          }
        </div>
        <div>
          {this.state.infoShow
            ? <InfoPanel
              togglePanel={this.togglePanel}
            />
            : null
          }
        </div>
        <div>
          {this.state.troubleShow
            ? <TroubleShootPanel
              togglePanel={this.togglePanel}
            />
            : null
          }
        </div>
        <div>
          {this.state.startOverShow
            ? <StartOverPanel
              togglePanel={this.togglePanel}
              updateMainObj={this.props.updateMainObj}
            />
            : null
          }
        </div>
      </div>
    )
  }
}

export default SideBar;