import React, { Component } from 'react';
import { GetIconColorFromScore } from '../../utils/tools';

function _getAccuracyColor(accuracy) {
  let iconColor = GetIconColorFromScore(accuracy, true);
  return iconColor;
}

export default class SVGIconComponent extends Component {
  render() {
    const color = this.props.color || "#f0f0f0";
    const accuracy = _getAccuracyColor(this.props.accuracy);

    return (
      <div>
        {
          this.props.name === "circleIcon" ?
            <svg id="circleIcon" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 20 20">
              <circle fill={accuracy} stroke="#ffffff" cx="10" cy="10" r="8" />
              <text fill="#ffffff" fontWeight="bold" fontFamily="Open Sans" fontSize="7px" textAnchor="middle" x="50%" y="60%">{this.props.label}</text>
            </svg>
            : null
        }
        {
          this.props.name === "historyBtnIcon" ?
            <svg id="historyBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 94.5 82.46">
              <line x1="13.31" y1="31.95" x2="33.29" y2="31.95" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
              <line x1="13.31" y1="45.11" x2="33.29" y2="45.11" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
              <line x1="13.31" y1="58.27" x2="33.29" y2="58.27" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
              <path d="M8.29,49.86V-15.36c0-4.05-9.41-7.33-22-7.33s-22,3.28-22,7.33V49.86a126.06,126.06,0,0,1,22-2C-5.39,47.86,4.29,48.59,8.29,49.86Z" transform="translate(36.96 31.35)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="4" />
              <path d="M56.29,36V-15.36c0-2.77-4.94-5.19-12-6.43" transform="translate(36.96 31.35)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="4" />
              <path d="M20.29-21.79c-7.33,1.22-12,3.59-12,6.43V49.86c12-1.27,15.68-2,24-2a149.82,149.82,0,0,1,24,2" transform="translate(36.96 31.35)" fill="none" stroke={color} strokeLinejoin="round" strokeWidth="4" />
              <path d="M41.19,15.58l-7.4-6.86a1.62,1.62,0,0,0-2.18,0l-8,6.93c-1,1-3.27.17-3.27-1.31V-30.1h24V14.29A1.91,1.91,0,0,1,41.19,15.58Z" transform="translate(36.96 31.35)" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
            </svg>
            : null
        }
        {
          this.props.name === "uploadBtnIcon" ?
            <svg id="uploadBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 108.09 53.77">
              <polygon stroke={color} fill="none" strokeWidth="4" strokeMiterlimit="10" points="104.67 15.11 3.44 15.11 20.01 1.25 88.28 1.26 104.67 15.11" />
              <polyline stroke={color} fill="none" strokeWidth="4" strokeMiterlimit="10" points="14.5 24.56 3.44 33.81 104.67 33.81 93.71 24.56" />
              <polyline stroke={color} fill="none" strokeWidth="4" strokeMiterlimit="10" points="14.5 43.26 3.44 52.52 104.67 52.52 93.71 43.26" />
            </svg>
            : null
        }
        {
          this.props.name === "infoBtnIcon" ?
            <svg id="infoBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 85.9 94.5">
              <path stroke={color} fill="none" strokeWidth="4" d="M35.7-36.09v16h16" transform="translate(32.96 37.34)" />
              <path fill="none" stroke={color} strokeWidth="4" d="M-16.3-22.12V-32.86a3.2,3.2,0,0,1,3.15-3.23H35.7a2.33,2.33,0,0,1,1.8.94L50.75-22.07a2.58,2.58,0,0,1,1,2v72.8a3.2,3.2,0,0,1-3.17,3.2H-13.15a3.18,3.18,0,0,1-3.15-3.2V42.07" transform="translate(32.96 37.34)" />
              <path stroke={color} fill="none" strokeWidth="4" d="M3.6,27.41A19.74,19.74,0,1,0-16.13,7.67,19.74,19.74,0,0,0,3.6,27.41Z" transform="translate(32.96 37.34)" />
              <path stroke={color} fill="none" strokeWidth="4" d="M-10.34,21.62-32.07,43.35" transform="translate(32.96 37.34)" />
            </svg>
            : null
        }
        {
          this.props.name === "layoutBtnIcon" ?
            <svg id="layoutBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 84.58 73.33">
              <polygon points="72.53 61.61 67.38 61.61 67.38 59.27 69.56 59.27 69.01 57.11 71.23 56.52 72.53 61.61" fill={color} stroke={color} strokeWidth="1" />
              <path d="M-20.22,35.36h6.92V33h-6.92Zm13.84,0H.53V33H-6.38Zm13.82,0h6.92V33H7.44Zm13.83,0h6.92V33H21.27Z" transform="translate(32.28 26.25)" fill={color} stroke={color} strokeWidth="1" />
              <polygon points="5.15 61.61 0 61.61 1.31 56.52 3.52 57.11 2.97 59.27 5.15 59.27 5.15 61.61" fill={color} stroke={color} strokeWidth="1" />
              <path d="M-19.25-6.29l-2.21-.6,1.9-7.43,2.22.6ZM-23,8.57-25.27,8l1.9-7.43,2.22.59Zm-3.81,14.86-2.21-.59,1.9-7.43,2.22.59Z" transform="translate(32.28 26.25)" fill={color} stroke={color} strokeWidth="1" />
              <polygon points="16.84 5.09 14.62 4.5 15.78 0 20.32 0 20.32 2.34 17.54 2.34 16.84 5.09" fill={color} stroke={color} strokeWidth="1" />
              <path d="M-5.58-23.91H.8v-2.34H-5.58Zm12.76,0h6.37v-2.34H7.18Z" transform="translate(32.28 26.25)" fill={color} stroke={color} strokeWidth="1" />
              <polygon points="55.7 5.09 54.99 2.34 52.21 2.34 52.21 0 56.76 0 57.91 4.5 55.7 5.09" fill={color} stroke={color} strokeWidth="1" />
              <path d="M27.22-6.29l-1.9-7.43,2.21-.6,1.91,7.43ZM31,8.57,29.12,1.14,31.34.55,33.24,8Zm3.8,14.86L32.93,16l2.21-.59,1.91,7.43Z" transform="translate(32.28 26.25)" fill={color} stroke={color} strokeWidth="1" />
              <polygon points="79.44 60.73 64.27 1.47 66.48 0.88 81.66 60.14 79.44 60.73" fill={color} stroke={color} strokeWidth="1" />
              <rect x="1.48" y="68.03" width="69.56" height="2.35" fill={color} stroke={color} strokeWidth="1" />
              <rect x="0.34" y="65.08" width="2.29" height="8.25" fill={color} stroke={color} strokeWidth="1" />
              <rect x="69.9" y="65.08" width="2.29" height="8.25" fill={color} stroke={color} strokeWidth="1" />
              <rect x="61.34" width="8.07" height="2.34" fill={color} stroke={color} strokeWidth="1" />
              <rect x="76.51" y="59.26" width="8.07" height="2.35" fill={color} stroke={color} strokeWidth="1" />
            </svg>
            : null
        }
        {
          this.props.name === "addPtsBtnIcon" ?
            <svg id="addPtsBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 94.5 93.99">
              <path id="Path_4109" fill="none" stroke={color} strokeWidth="4" d="M52.11,29l3.88.73V-31.65L31.45-35.78,7.76-24.86l-20-9.21L-36-25V36.31l23.8-9L2.13,33.75" transform="translate(37.26 37.08)" />
              <path id="Path_4110" fill="none" stroke={color} strokeWidth="4" d="M-12.21-22.44V16" transform="translate(37.26 37.08)" />
              <path id="Path_4111" fill="none" stroke={color} strokeWidth="4" d="M7.76-14.76V-.09" transform="translate(37.26 37.08)" />
              <path id="Path_4112" fill="none" stroke={color} strokeWidth="4" d="M31.88-25.17V-5.74" transform="translate(37.26 37.08)" />
              <path id="Path_4114" fill="none" stroke={color} strokeWidth="4" d="M25.86,24.24a4.12,4.12,0,1,0-4.15-4.12,4.12,4.12,0,0,0,4.15,4.12Z" transform="translate(37.26 37.08)" />
              <path id="Path_4115" fill="none" stroke={color} strokeWidth="4" d="M26,56.22S9.21,30.88,9.21,21.67A16.71,16.71,0,0,1,26,5c8.75,0,17.27,7.8,16.76,16.67-.25,4.34-2.94,10.4-5.57,15.6-2.47,4.89-4.88,8.84-4.88,8.84" transform="translate(37.26 37.08)" />
            </svg>
            : null
        }
        {
          this.props.name === "legendBtnIcon" ?
            <svg id="legendBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 94.5 94.5">
              <path id="Path_4151" d="M55.9-26.44a9.46,9.46,0,0,0-9.46-9.46H-26.64a9.45,9.45,0,0,0-9.46,9.46V46.64a9.46,9.46,0,0,0,9.46,9.46H46.44a9.47,9.47,0,0,0,9.46-9.46h0V.15" transform="translate(37.35 37.15)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4152" d="M36-11.9h-28" transform="translate(37.35 37.15)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4153" d="M36,12.1h-28" transform="translate(37.35 37.15)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4154" d="M36,36.1h-28" transform="translate(37.35 37.15)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4157" d="M-14.11,40.07a6,6,0,0,0,6-6,6,6,0,0,0-6-6,6,6,0,0,0-5.95,5.95,6,6,0,0,0,5.95,6Z" transform="translate(37.35 37.15)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4158" d="M-19.81-12.55l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4159" d="M-19.81,10.45l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke={color} strokeWidth="4" />
            </svg>
            : null
        }
        {
          this.props.name === "toolsBtnIcon" ?
            <svg id="toolsBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 76.59 94.97">
              <path id="Path_4064" d="M-8.49,46.1h0A3,3,0,0,0-5.5,49a3,3,0,0,0,3-2.93h0a2.94,2.94,0,0,0-3-2.92A3,3,0,0,0-8.49,46.1Z" transform="translate(28.47 37.55)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4065" d="M-27.22-17.7a21,21,0,0,1,9.54-17.55v13.76l5.75,8.6H.42l5.75-8.6V-35.25A21,21,0,0,1,15.71-17.7C15.71-10.21,8.6-3.63,2.61.11L5.74,44.72A11.5,11.5,0,0,1-5.94,56a11.49,11.49,0,0,1-11.3-11.31L-14.12.12a37.39,37.39,0,0,1-9.23-8" transform="translate(28.47 37.55)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4066" d="M43.31,19a5.57,5.57,0,0,1,3.56-4.87V5.8H22.58v8.29A5.57,5.57,0,0,1,26.14,19a5.55,5.55,0,0,1-3.56,4.86V46.47c0,5.35,5.44,9.7,12.15,9.7s12.15-4.35,12.15-9.7V23.82A5.54,5.54,0,0,1,43.32,19Z" transform="translate(28.47 37.55)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4067" d="M34.72-4.64V-25.52" transform="translate(28.47 37.55)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4068" d="M37.14-35.84H32.31l-1.87,4.42,1.87,5.9h4.83L39-31.42Z" transform="translate(28.47 37.55)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4069" d="M34.72,27.36V46.1" transform="translate(28.47 37.55)" fill="none" stroke={color} strokeWidth="4" />
            </svg>
            : null
        }
        {
          this.props.name === "saveBtnIcon" ?
            <svg id="saveBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 94.1 90">
              <path id="Path_4084" d="M34.6,21.28,48,13.06l8.37,13.63" transform="translate(36.68 32.45)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4085" d="M47.78,14.39a41.69,41.69,0,1,1-10-31.17" transform="translate(36.68 32.45)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_4087" d="M-10.45,13.72,0,26.74l22.42-28" transform="translate(36.68 32.45)" fill="none" stroke={color} strokeWidth="4" />
            </svg>
            : null
        }
        {
          this.props.name === "printBtnIcon" ?
            <svg id="printBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 99.24 90">
              <path d="M302.31,129.81a8,8,0,0,0-8-8H246.2a8,8,0,0,0-8,8v21.34a.19.19,0,0,0,.18.19h63.84a.1.1,0,0,0,.09-.1Z" transform="translate(-220.63 -120.56)" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
              <rect x="17.56" y="58.9" width="64.12" height="28.12" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
              <polygon points="1.25 30.78 1.25 71.09 17.56 71.09 17.56 58.9 81.68 58.9 81.68 71.09 97.99 71.09 97.99 30.78 1.25 30.78" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
              <line x1="30.08" y1="68.74" x2="69.17" y2="68.74" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
              <line x1="30.08" y1="77.48" x2="69.17" y2="77.48" fill="none" stroke={color} strokeMiterlimit="10" strokeWidth="4" />
            </svg>
            : null
        }
        {
          this.props.name === "startOverBtnIcon" ?
            <svg id="startOverBtnIcon" xmlns="http://www.w3.org/2000/svg" height={this.props.size} viewBox="0 0 93.61 90.15">
              <path id="Path_3881" d="M11.35,17.88H31.51V53.67H11.35Z" transform="translate(37.28 35.17)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_3879" d="M47.64,14.08V53.73H-28.58V14.08" transform="translate(37.28 35.17)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_3880" d="M-12.59,17.86H-.43V30H-12.59Z" transform="translate(37.28 35.17)" fill="none" stroke={color} strokeWidth="4" />
              <path id="Path_3882" d="M-36.47,5.3l46-38.84,46,38.84" transform="translate(37.28 35.17)" fill="none" stroke={color} strokeWidth="4" />
            </svg>
            : null
        }
        {
          this.props.name === "topLeftIcon" ?
            <svg id="topLeftIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" height="14px">
              <rect x="0.63" y="0.5" width="6" height="6" fill="#25408f" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="0.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="10.26" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="0.5" y="10.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
            </svg>
            : null
        }
        {
          this.props.name === "topRightIcon" ?
            <svg id="topRightIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" height="14px">
              <rect x="0.63" y="0.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="0.5" width="6" height="6" fill="#25408f" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="10.26" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="0.5" y="10.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
            </svg>
            : null
        }
        {
          this.props.name === "bottomRightIcon" ?
            <svg id="bottomRightIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" height="14px">
              <rect x="0.63" y="0.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="0.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="10.26" width="6" height="6" fill="#25408f" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="0.5" y="10.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
            </svg>
            : null
        }
        {
          this.props.name === "bottomLeftIcon" ?
            <svg id="bottomLeftIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" height="14px">
              <rect x="0.63" y="0.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="0.5" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="10.5" y="10.26" width="6" height="6" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <rect x="0.5" y="10.5" width="6" height="6" fill="#25408f" stroke="#25408f" strokeMiterlimit="10" />
            </svg>
            : null
        }
        {
          this.props.name === "legendSmallIcon" ?
            <svg id="legendSmallIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height="14px">
              <rect width="20" height="20" fill="#ffffff" />
              <rect x="6" y="6" width="8" height="8" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <line x1="7" y1="8" x2="13" y2="8" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
              <line x1="7" y1="10" x2="13" y2="10" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
              <line x1="7" y1="12" x2="13" y2="12" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
            </svg>
            : null
        }
        {
          this.props.name === "legendDefaultIcon" ?
            <svg id="legendDefaultIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height="14px">
              <rect width="20" height="20" fill="#ffffff" />
              <rect x="4" y="4" width="12" height="12" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <line x1="6" y1="7" x2="14" y2="7" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
              <line x1="6" y1="10" x2="14" y2="10" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
              <line x1="6" y1="13" x2="14" y2="13" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
            </svg>
            : null
        }
        {
          this.props.name === "legendLargeIcon" ?
            <svg id="legendLargeIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" height="14px">
              <rect width="20" height="20" fill="#ffffff" />
              <rect x="2" y="2" width="16" height="16" fill="none" stroke="#25408f" strokeMiterlimit="10" />
              <line x1="5" y1="6" x2="15" y2="6" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
              <line x1="5" y1="10" x2="15" y2="10" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
              <line x1="5" y1="14" x2="15" y2="14" fill="none" stroke="#25408f" strokeMiterlimit="10" strokeWidth="1" />
            </svg>
            : null
        }
      </div>
    );
  }
}