import React from 'react';
import MainPage from './MainPage/MainPage';
import Support from './Support/Support';
import RouteError from './RouteError/RouteError';
import { useAuth0 } from './react-auth0-spa';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import './Mobile.css';

function App() {
  const { isAuthenticated, loginWithRedirect, loading, getTokenSilently, user } = useAuth0();

  if (loading) {
    return <div className="loader"></div>;
  }

  if (isAuthenticated) {
    return (
      <Switch>
        <Route exact path="/" render={(props) => <MainPage {...props} loginWithRedirect={loginWithRedirect} getTokenSilently={getTokenSilently} user={user} />} />
        <Route path="/support" component={Support} />
        <Route component={RouteError} />
      </Switch>
    );
  } else {
    loginWithRedirect({});
    return (
      <div className="loader"></div>
    )
  }
}

export default App;
