import React from 'react';
import TextField from '@material-ui/core/TextField';
import ReviewResults from './ReviewResults';

class QuickGeoPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targetGroup: 0,
      reviewResultsShow: false,
      data: ''
    }

    this.setData = this.setData.bind(this);
    this.quickGeo = this.quickGeo.bind(this);
  }

  async quickGeo() {
    const togglePanel = await this.props.geocode(this.state.data, true);
    if (togglePanel) {
      this.setState({
        reviewResultsShow: true
      });
    } else {
      this.setState({
        reviewResultsShow: false
      });
    }
  }

  setData(e) {
    this.setState({
      data: e.target.value
    });
  }

  render() {
    var loadingClass = this.props.geocodeLoading ? 'loaderShow' : 'loaderHide';
    var mainClass = this.props.geocodeLoading ? 'loaderHide' : 'loaderShow';
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="legendBtnIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 94.5 94.5">
              <path id="Path_4151" d="M55.9-26.44a9.46,9.46,0,0,0-9.46-9.46H-26.64a9.45,9.45,0,0,0-9.46,9.46V46.64a9.46,9.46,0,0,0,9.46,9.46H46.44a9.47,9.47,0,0,0,9.46-9.46h0V.15" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4152" d="M36-11.9h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4153" d="M36,12.1h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4154" d="M36,36.1h-28" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4157" d="M-14.11,40.07a6,6,0,0,0,6-6,6,6,0,0,0-6-6,6,6,0,0,0-5.95,5.95,6,6,0,0,0,5.95,6Z" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4158" d="M-19.81-12.55l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
              <path id="Path_4159" d="M-19.81,10.45l3.7,4.62,8-10" transform="translate(37.35 37.15)" fill="none" stroke="#0c9ed9" strokeWidth="4" />
            </svg>
          </span>
          Quick Geocode
        </h2>
        <div className="panel-body">
          {!this.state.reviewResultsShow ?
            <div>
              <div className={loadingClass}>
                <div className="panel-loader"></div>
              </div>
              <div className={mainClass}>
                <div className="panel-body-small-text">
                  Copy and paste addresses separated by a new line.
                </div>
                <div className="padding-t10">
                  <TextField
                    id="quickGeoInput"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={10}
                    style={{ width: '100%' }}
                    onChange={e => this.setData(e)}
                  />
                </div>
                <div className="margin-t20 flexRight">
                  <button className="geocodeBtn" onClick={this.quickGeo} disabled={this.state.data.trim() === '' ? true : false}>Geocode</button>
                </div>
                {this.props.geocodeError ?
                  <div className="errorMsgDiv">
                    Oops!  Something went wrong.  Please try again.
                  </div>
                  : null
                }
              </div>
            </div>
            : null
          }
          {this.state.reviewResultsShow ?
            <ReviewResults
              token={this.props.token}
              points={this.props.points}
              updateMainObj={this.props.updateMainObj}
              getIcon={this.props.getIcon}
              currentPointId={this.props.currentPointId}
            />
            : null
          }
        </div>
      </div>
    );
  }
}

export default QuickGeoPanel;