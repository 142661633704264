import React from 'react';
import Menu from '@material-ui/core/Menu';
import XLSX from 'xlsx';
import { GetExportArray, GetIconColorFromScore } from '../../utils/tools';
import FileSaver from 'file-saver';

class DownloadMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      anchorEl: null
    }

    this.download = this.download.bind(this);
    this._getKmlPlaceMarks = this._getKmlPlaceMarks.bind(this);
  }

  toggleOptionsPanel(e, bool) {
    this.setState({
      anchorEl: e.currentTarget,
      showOptions: bool
    });
  }

  download(type) {
    if (type !== 'kml') {
      const filename = "geocoderResults." + type;
      var isIntl=false;
      if(this.props.useIntl&&this.props.countryColName.toLowerCase()!=='usonly')
        isIntl = true;

      const data = GetExportArray(this.props.points,isIntl);
      const ws_name = "GeocoderResults";

      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(data);

      /* add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      /* write workbook */
      XLSX.writeFile(wb, filename);
    } else {
      let kmlStart = '<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document><name>GeocoderResults</name>';
      let kmlPlaceMarks = this._getKmlPlaceMarks();
      let kmlEnd = '</Document></kml>';
      var blob = new Blob([kmlStart + kmlPlaceMarks + kmlEnd], {
        type: "text/plain;charset=utf-8"
       });
      FileSaver.saveAs(blob, 'geocoderResults.kml');
    }
  }

  _getKmlPlaceMarks() {
    let placeMarkTxt = '';
    for (const point of this.props.points) {
      const iconColor = this._getIconColor(point.content.score);
      placeMarkTxt += '<Placemark><name>' + point.content.id +
      '</name><description>' + point.content.address + 
      '</description><Style><IconStyle><color>' + iconColor + '</color><Icon><href>http://maps.google.com/mapfiles/kml/paddle/wht-blank.png</href></Icon></IconStyle></Style><Point><coordinates>' 
      + point.position.lng + ',' + point.position.lat + '</coordinates></Point></Placemark>';
    }
    return placeMarkTxt;
  }

  _getIconColor(score) {
    let iconColor = GetIconColorFromScore(score);
    return iconColor;
  }

  render() {
    return (
      <div>
        <div>
          <button className="geocodeBtn" onClick={(e) => this.toggleOptionsPanel(e, true)}>Download</button>
        </div>
        {this.state.showOptions ?
          <Menu
            id="download-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={this.state.showOptions}
            onClose={this.toggleOptionsPanel.bind(this, { currentTarget: null }, false)}
          >
            <div className="downloadMenuDiv">
              <div className="padding-6">
                <button className="geocodeBtnSmall" onClick={this.download.bind(this, 'xlsx')}>XLSX</button>
              </div>
              <div className="padding-6">
                <button className="geocodeBtnSmall" onClick={this.download.bind(this, 'csv')}>CSV</button>
              </div>
              <div className="padding-6">
                <button className="geocodeBtnSmall" onClick={this.download.bind(this, 'kml')}>KML</button>
              </div>
            </div>
          </Menu>
          : null
        }
      </div>
    );
  }
}

export default DownloadMenu;