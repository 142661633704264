import React from 'react';
import XLSX from 'xlsx';
import { IsObjectEmpty, ReplaceKeys } from '../../utils/tools';
import MapColumns from './MapColumns';
import ReviewResults from './ReviewResults';

class UploadPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targetGroup: 0,
      fileUploadShow: true,
      mapColumnsShow: false,
      reviewResultsShow: false,
      data: null,
      columns: [],
      addressColName: '',
      cityColName: '',
      stateColName: '',
      postalColName: '',
      countryColName: '',
      error: false,
      limitError1: false
    }
    this.closePanel = this.closePanel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.togglePanels = this.togglePanels.bind(this);
    this.mapColumnNames = this.mapColumnNames.bind(this);
    this.backToAddPts = this.backToAddPts.bind(this);
  }

  closePanel() {
    this.props.togglePanel('addPt');
  }

  togglePanels(panel1, panel2, panel3) {
    this.props.clearErrors();
    this.setState({
      fileUploadShow: panel1,
      mapColumnsShow: panel2,
      reviewResultsShow: panel3
    });
  }

  handleChange(e) {
    this.setState({
      error: false,
      limitError: false
    });
    const files = e.target.files;
    if (files && files[0]) {
      const file = files[0];
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        const isWBEmpty = IsObjectEmpty(wb);
        if (isWBEmpty || !wb.SheetNames || !wb.Sheets) {
          this.setState({
            error: true
          });
          return;
        }

        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        //make sure worksheet is not empty
        const isWSEmpty = IsObjectEmpty(ws);
        if (isWSEmpty) {
          this.setState({
            error: true
          });
          return;
        }
        /* Convert array of arrays */
        const columns = this.make_cols(ws);
        if (columns.length === 0) { //if no columns
          this.setState({
            error: true
          });
          return;
        }

        const data = XLSX.utils.sheet_to_json(ws, {raw: false}, { header: columns });

        console.log(process.env.REACT_APP_USLIMIT,'limit1');
        if(data.length > process.env.REACT_APP_USLIMIT){
          this.setState({
            limitError1: true,
          });
          return;
        }

        if (data.length < 1) { //if data has nothing, or only a header
          this.setState({
            error: true
          });
          return;
        }

        ReplaceKeys(data);
        //show uploaded columns in map columns panel
        this.setState({
          data: data,
          columns: columns
        });


        this.mapColumnNames('addressColName');
        this.mapColumnNames('cityColName');
        this.mapColumnNames('stateColName');
        this.mapColumnNames('postalColName');
        this.mapColumnNames('countryColName');
        

        this.togglePanels(false, true, false);
      };
      if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
    } else {
      return;
    }
  };

  make_cols(ws) {
    const header = [];
    let columnCount = 0;
    if (ws['!ref']) {
      columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
    } else {
      return [];
    }

    for (let i = 0; i < columnCount; ++i) {
      if (ws[`${XLSX.utils.encode_col(i)}1`]) {
        header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v;
        header[i] = header[i].toString().trim();
      } else {
        header[i] = 'no header';
      }
    }
    return header;
  };

  mapColumnNames(colName, custom) {

    if (colName === 'addressColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'address') || (columnLowerCase === 'addr') || (columnLowerCase === '_addr') || (columnLowerCase === 'street')
          || (columnLowerCase === '_street') || (columnLowerCase === '_address') || (columnLowerCase === 'property address') || (columnLowerCase === 'propertyaddress')) {
          this.setState({
            addressColName: column
          });
          return;
        }
      }
      this.setState({
        addressColName: 'None'
      });
    } else if (colName === 'cityColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'city') || (columnLowerCase === '_city') || (columnLowerCase === 'municipality') || (columnLowerCase === '_municipality')
          || (columnLowerCase === 'town') || (columnLowerCase === 'property city') || (columnLowerCase === 'propertycity')) {
          this.setState({
            cityColName: column
          });
          return;
        }
      }
      this.setState({
        cityColName: 'None'
      });
    } else if (colName === 'stateColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'state') || (columnLowerCase === '_state') || (columnLowerCase === 'province') || (columnLowerCase === '_province')
        || (columnLowerCase === 'property state') || (columnLowerCase === 'propertystate')) {
          this.setState({
            stateColName: column
          });
          return;
        }
      }
      this.setState({
        stateColName: 'None'
      });
    } else if (colName === 'postalColName' && !custom) {
      for (const column of this.state.columns) {
        const columnLowerCase = column.toLowerCase().trim();
        if ((columnLowerCase === 'zip') || (columnLowerCase === '_zip') || (columnLowerCase === 'postalcode') || (columnLowerCase === '_postalcode')
          || (columnLowerCase === 'postal') || (columnLowerCase === '_postal') || (columnLowerCase === 'zipcode') || (columnLowerCase === '_zipcode')
          || (columnLowerCase === 'zip code') || (columnLowerCase === 'postal code') || (columnLowerCase === 'property zip') || (columnLowerCase === 'propertyzip')) {
          this.setState({
            postalColName: column
          });
          return;
        }
      }
      this.setState({
        postalColName: 'None'
      });
    } else if (colName === 'countryColName' && !custom) {
      this.setState({
        countryColName: 'USOnly'
      });
    } else if (custom) {
      this.setState({
        [colName]: custom
      })
    }
  }

  backToAddPts() {
    this.props.toggleUploadPanel(false, true);
  }

  // list of supported file types
  SheetJSFT = [
    "xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
  ].map(function (x) { return "." + x; }).join(",");

  render() {
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <svg id="uploadIcon" xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 108.09 53.77">
              <polygon stroke="#0c9ed9" fill="none" strokeWidth="4" strokeMiterlimit="10" points="104.67 15.11 3.44 15.11 20.01 1.25 88.28 1.26 104.67 15.11" />
              <polyline stroke="#0c9ed9" fill="none" strokeWidth="4" strokeMiterlimit="10" points="14.5 24.56 3.44 33.81 104.67 33.81 93.71 24.56" />
              <polyline stroke="#0c9ed9" fill="none" strokeWidth="4" strokeMiterlimit="10" points="14.5 43.26 3.44 52.52 104.67 52.52 93.71 43.26" />
            </svg>
          </span>
          Upload Locations
        </h2>
        <div className="panel-body">
          {this.state.fileUploadShow
            ? <div>
              <div className="panel-body-small-text">
                Upload csv or xlsx of addresses, latitude/longitude coordinates, or intersections.
              </div>
              <div className="margin-t20 flex">
                <input className="fileUploadInput" type="file" accept={this.SheetJSFT} id="uploadFileBtn" onChange={this.handleChange} />
                <label htmlFor="uploadFileBtn">
                  <span className="geocodeBtn button-appearance">Select File</span>
                </label>
                <button className="geocodeBtn" onClick={this.closePanel}>Back</button>
              </div>
            </div>
            : null
          }
          {this.state.mapColumnsShow ?
            <MapColumns
              columns={this.state.columns}
              mapColumnNames={this.mapColumnNames}
              addressColName={this.state.addressColName}
              cityColName={this.state.cityColName}
              stateColName={this.state.stateColName}
              postalColName={this.state.postalColName}
              countryColName={this.state.countryColName}
              togglePanels={this.togglePanels}
              data={this.state.data}
              geocode={this.props.geocode}
              geocodeLoading={this.props.geocodeLoading}
              geocodeError={this.props.geocodeError}
              limitError={this.props.limitError}
              useIntl={this.props.useIntl}
            />
            : null
          }
          {this.state.reviewResultsShow ?
            <ReviewResults
              token={this.props.token}
              useIntl={this.props.useIntl}
              countryColName={this.state.countryColName}
              points={this.props.points}
              updateMainObj={this.props.updateMainObj}
              getIcon={this.props.getIcon}
              currentPointId={this.props.currentPointId}
            />
            : null
          }
          {this.state.error ?
            <div className="errorMsgDiv">Oops! Something went wrong.  Please check data and try again.</div>
            : null
          }
          {
            this.props.limitError ?
            <div className="errorMsgDiv">This file exceeds {this.props.limit} records.  Please remove records and try again.</div>
            : null
          }
          {
            this.state.limitError1 ?
            <div className="errorMsgDiv">This file exceeds {process.env.REACT_APP_USLIMIT} records.  Please remove records and try again.</div>
            : null
          }
        </div>
      </div>
    );
  }
}

export default UploadPanel;