export function IsObjectEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }

  return true;
}

export function GetExportArray(points,useIntl) {
  //start array with array of column headers
  let exportArray = [['Id', 'InputAddress','InputCity','InputRegion','InputPostal', 'OutputAddress','OutputCity','OutputRegion','OutputPostal',
  'Latitude', 'Longitude', 'Score', 'Type']];

  if(useIntl)
    exportArray = [['Id', 'InputAddress','InputCity','InputRegion','InputPostal','InputCountry', 'OutputAddress','OutputCity','OutputRegion'
    ,'OutputPostal','OutputCountry','Latitude', 'Longitude', 'Score', 'Type']];   

  for (const point of points) {
    let rowArray = [];
    rowArray.push(point.content.id);
    rowArray.push(point.content.address);
    rowArray.push(point.content.city);
    rowArray.push(point.content.region);
    rowArray.push(point.content.postalcode);
    if(useIntl)
      rowArray.push(point.content.country);
    rowArray.push(point.content.outputaddress);
    rowArray.push(point.content.outputcity);
    rowArray.push(point.content.outputregion);
    rowArray.push(point.content.outputpostalcode);
    if(useIntl)
      rowArray.push(point.content.outputcountry);
    rowArray.push(point.position.lat);
    rowArray.push(point.position.lng);
    rowArray.push(point.content.score);
    rowArray.push(point.content.type);
    exportArray.push(rowArray);
  }
  return exportArray;
}

export function ReplaceKeys(object) {
  Object.keys(object).forEach(function (key) {
      var newKey = key.trim();
      if (object[key] && typeof object[key] === 'object') {
          ReplaceKeys(object[key]);
      }
      if (key !== newKey) {
          object[newKey] = object[key];
          delete object[key];
      }
  });
}

export function MapDataToPointsArray(data, isQuickGeo, addressRef, cityRef, stateRef, postalRef,countryRef,isIntl) {
  let geocodeArray = [];
  let addrData = data;
  if (isQuickGeo) {
    addrData = data.trim();
    addrData = addrData.split('\n');
  }

  if(countryRef==='USOnly')
    isIntl = false;

  const newArray = Array.from(addrData);

  if (isQuickGeo) {
    for (const [index, row] of newArray.entries()) {
      geocodeArray.push({
          OBJECTID: index,
          SingleLine: encodeURIComponent(row)
      });
    }
  } else {
    for (const [index, row] of newArray.entries()) {
      geocodeArray.push({
          OBJECTID: index,
          streetAddress: encodeURIComponent(row[addressRef]),
          city: row[cityRef],
          state: row[stateRef],
          postalCode: row[postalRef],
          country: isIntl ? row[countryRef]  : "UNITED STATES"
      });
    }
  }
  
  const addresses = {
    addresses: geocodeArray,
    intl: isIntl
  }

  return addresses;
}

export function FormatResults(results, isQuickGeo) {
  const resultPoints = [];
  results.forEach(result => {
    if (result.output[0].location) {
      resultPoints.push({
        content: {
          id: result.input.attributes ? result.input.attributes.OBJECTID + 1 : result.input.OBJECTID + 1,
          lat: result.output[0].location.y,
          lng: result.output[0].location.x,
          address: isQuickGeo ? (result.input.attributes ? decodeURIComponent(result.input.attributes.SingleLine) : decodeURIComponent(result.input.SingleLine)) : decodeURIComponent(result.input.attributes.Address + " ," + result.input.attributes.City + " ," + result.input.attributes.Region + " " + result.input.attributes.Postal),
          city: isQuickGeo ? null :  decodeURIComponent(result.input.attributes.City),
          region: isQuickGeo? null : decodeURIComponent(result.input.attributes.Region),
          postalcode: isQuickGeo ? null : decodeURIComponent(result.input.attributes.Postal),
          country: result.input.attributes ? result.input.attributes.Country || 'UNITED STATES' : 'UNITED STATES',
          score: result.output[0].score,
          type: result.output[0].attributes.Addr_type,
          candidates: result.output,
          outputaddress : result.output[0].attributes.StAddr,
          outputcity: decodeURIComponent(result.output[0].attributes.City),
          outputregion: decodeURIComponent(result.output[0].attributes.RegionAbbr),
          outputpostalcode: decodeURIComponent(result.output[0].attributes.Postal),
          outputcountry: decodeURIComponent(result.output[0].attributes.Country),
          isquickgeo: isQuickGeo
        },
        position: { lat: result.output[0].location.y, lng: result.output[0].location.x }
      });
    } else {
      resultPoints.push({
        content: {
          id: result.input.attributes.OBJECTID + 1,
          lat: 0,
          lng: 0,
          address: isQuickGeo ? result.input.attributes.SingleLine : result.input.attributes.Address + " ," + result.input.attributes.City + " ," + result.input.attributes.Region + " " + result.input.attributes.Postal, 
          score: result.output[0].score,
          type: 'No Address',
          candidates: [{
            address: 'No Address',
            attributes: {
              Addr_type: 'NoAddress',
              Score: 0
            },
            location: {
              x: 0,
              y: 0
            },
            score: 0
          }]
        },
        position: { lat: 0, lng: 0 }
      });
    }
    
  });

  return resultPoints;
}

export function SelectFirstCandidate(points) {
  let resultPoints = Array.from(points);
  for (const point of resultPoints) {
    for (const [index, candidate] of point.content.candidates.entries()) {
      if (index === 0) {
        candidate.selected = true;
        break;
      }
    }
  }

  return resultPoints;
}

export function GetIconColorFromScore(accuracy, isIcon) {
  if (accuracy === 100) {
    if (isIcon) {
      return '#0cd90f';
    } else {
      return 'ff0fd90c';
    }
  } else if (accuracy < 100 && accuracy >= 95) {
    if (isIcon) {
      return '#ffd400';
    } else {
      return 'ff00d4ff';
    }
  } else if (accuracy < 95) {
    if (isIcon) {
      return '#ed1b34';
    } else {
      return 'ff341bed';
    }
  } else if (accuracy === 999) {
    if (isIcon) {
      return '#0c9ed9';
    } else {
      return 'ffd99e0c';
    }
  } else {
    if (isIcon) {
      return '#ed1b34';
    } else {
      return 'ff341bed';
    }
  }
}