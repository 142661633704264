import React from 'react';
import Script from 'react-load-script';
import { Map, Marker, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { latLngBounds } from 'leaflet';
import '../../utils/esri-leaflet-vector/mapbox-gl.css';
import '../../App.css';
import CustomMapBtns from './CustomMapBtns';

class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      esriLeafletLoaded: false,
      esriVectorLoaded: false,
      mapBoxGLLoaded: false,
      basemap: 'colliers-grey',
      prevMapCenter: this.props.mapCenter,
      prevMapZoom: this.props.mapZoom,
      prevStartOver: this.props.isStartOver,
      zoomToAll: false,
      currentZoom: this.props.mapZoom,
    };

    this._getMapPadding = this._getMapPadding.bind(this);
    this.updateBasemap = this.updateBasemap.bind(this);
    this.updateMarkerPosition = this.updateMarkerPosition.bind(this);
    this.updateMapState = this.updateMapState.bind(this);
    this.handleZoomEnd = this.handleZoomEnd.bind(this);
  }

  componentDidUpdate() {
    if (this.state.prevMapZoom !== this.props.mapZoom || this.state.prevMapCenter !== this.props.mapCenter) {
      this.setState({
        prevMapCenter: this.props.mapCenter,
        prevMapZoom: this.props.mapZoom
      });
    }

    if (this.props.isStartOver) {
      const mapRef = this.refs.map.leafletElement;
      mapRef.setView(this.props.mapCenter, this.props.mapZoom);
      this.setState({
        prevMapCenter: this.props.mapCenter,
        prevMapZoom: this.props.mapZoom
      });
    }

    if (this.props.geocodingSuccess === true || this.state.zoomToAll) {
      const mapRef = this.refs.map.leafletElement;

      if (this.props.points.length > 0) {
        let mapBounds = latLngBounds([this.props.points[0].position.lat, this.props.points[0].position.lng]);

        if (this.props.points.length === 1) {
          mapRef.setView([this.props.points[0].position.lat, this.props.points[0].position.lng], this.props.mapZoom);
        } else {
          this.props.points.forEach((point) => {
            mapBounds.extend([point.position.lat, point.position.lng]);
          });

          const paddingArray = this._getMapPadding(mapRef);
          if (mapBounds._northEast.lat === 0 && mapBounds._northEast.lng === 0 && mapBounds._southWest.lat === 0 && mapBounds._southWest.lng === 0) {
            mapBounds._northEast.lat = 5;
            mapBounds._northEast.lng = -5;
            mapBounds._southWest.lat = -5;
            mapBounds._southWest.lng = 5;
          } else if (mapBounds._northEast.lat === mapBounds._southWest.lat && mapBounds._northEast.lng === mapBounds._southWest.lng) {
            mapBounds._northEast.lat = mapBounds._northEast.lat + 0.05;
            mapBounds._northEast.lng = mapBounds._northEast.lng - 0.05;
            mapBounds._southWest.lat = mapBounds._southWest.lat - 0.05;
            mapBounds._southWest.lng = mapBounds._southWest.lng + 0.05;
          }
            
          mapRef.fitBounds(mapBounds, { paddingTopLeft: [(440 + paddingArray[0]), (20 + paddingArray[1])], paddingBottomRight: [20, 20] });
          this.props.updateMainObj({ geocodingSuccess: false });
        }
      }

      if (this.state.zoomToAll) {
        this.setState({ zoomToAll: false });
      }
    }
  }

  _getMapPadding(mapRef) {
    let xPadding = 0;
    let yPadding = 0;
    const pixelBounds = mapRef.getPixelBounds();
    const pixelBoundsArray = [Math.round(pixelBounds.max.x - pixelBounds.min.x), Math.round(pixelBounds.max.y - pixelBounds.min.y)];

    const paddingArray = [xPadding, yPadding];
    return paddingArray;
  }

  updateBasemap(value) {
    const map1 = this.refs.map.leafletElement;

    map1.eachLayer(function (layer) {
      if (layer._mapboxGL && layer._mapboxGL.options.accessToken === 'ezree')
        map1.removeLayer(layer);
      if (layer.options && layer.options.pane === 'tile') {
        map1.removeLayer(layer);
      }
    });

    if (value === 'hybrid') {
      map1.createPane('tile');
      map1.getPane('tile').style.zIndex = 150;
      L.esri.basemapLayer('ImageryFirefly', { pane: 'tile' }).addTo(map1);
      L.esri.Vector.layer('c116ff57c1b9474c9924b07fd45ae60d').addTo(map1);
    } else {
      L.esri.Vector.layer('855a4d90d0f34769aa5f9672ef65e5a3').addTo(map1);
    }

    var originalInitTile = L.GridLayer.prototype._initTile
    L.GridLayer.include({
      _initTile: function (tile) {
        originalInitTile.call(this, tile);

        var tileSize = this.getTileSize();

        tile.style.width = tileSize.x + 1 + 'px';
        tile.style.height = tileSize.y + 1 + 'px';
      }
    });

    //move map slightly to refresh
    setTimeout(() => {
      const currentCenter = map1.getCenter();
      map1.setView([currentCenter.lat + 0.02, currentCenter.lng + 0.02], map1.getZoom());
      map1.setView([(currentCenter.lat + 0.02) - 0.02, (currentCenter.lng + 0.02) - 0.02], map1.getZoom());
    }, 500);

    this.setState({
      basemap: value
    });
  }

  updateMarkerPosition = (point, e) => {
    const currentPoint = point.content;
    const newPosition = e.target.getLatLng();

    const pointsArrayClone = Array.from(this.props.points);
    pointsArrayClone.forEach(point => {
      if (point.content.id === currentPoint.id) {
        point.position = newPosition;
        point.content.lat = newPosition.lat;
        point.content.lng = newPosition.lng;
        point.content.score = 999;
        point.content.icon = this.props.getIcon(point);
        point.content.type = 'Custom';
        
        point.content.outputaddress = '';
        point.content.outputcity = '';
        point.content.outputregion = '';
        point.content.outputpostalcode = '';

        //remove previous custom locations
        let newCandidates = point.content.candidates.filter(candidate => candidate.attributes.Score !== 999);

        //make all selected attributes false
        for (const candidate of newCandidates) {
          candidate.selected = false;
        }

        newCandidates.push({
          address: 'Custom location',
          attributes: {
            Score: 999,
            Addr_type: 'Custom'
          },
          location: {
            x: newPosition.lng,
            y: newPosition.lat
          },
          selected: true
        });
        point.content.candidates = newCandidates;
      }
    });

    pointsArrayClone.sort((a, b) => (a.content.id > b.content.id) ? 1 : ((b.content.id > a.content.id) ? -1 : 0));

    this.props.updateMainObj({ mapPoints: pointsArrayClone, mapCenter: [newPosition.lat, newPosition.lng], mapZoom: 16 });
  }

  handleMapClick(point) {
    this.props.updateMainObj({ currentPointId: point.content.id });
  }

  handleZoomEnd() {
    const map1 = this.refs.map.leafletElement;
    const mapZoom = map1.getZoom();
    this.setState({
      currentZoom: mapZoom
    });
  }

  updateMapState(updateObj) {
    this.setState(updateObj);
  }

  onMapBoxGLLoaded() {
    this.setState({ mapBoxGLLoaded: true });
  }

  onEsriLeafletLoaded() {
    this.setState({ esriLeafletLoaded: true });
  }

  onEsriVectorLoaded() {
    this.setState({ esriVectorLoaded: true });

    const map1 = this.refs.map.leafletElement;
    L.esri.Vector.layer('855a4d90d0f34769aa5f9672ef65e5a3').addTo(map1);
  }

  render() {
    return (
      <div className="mapContainer" id="mapDiv">
        <Script
          url={process.env.PUBLIC_URL + 'basemap-plugin/mapbox-gl.js'}
          onLoad={this.onMapBoxGLLoaded.bind(this)}
        />
        {this.state.mapBoxGLLoaded ?
          <Script
            key="esriLeaflet"
            url={process.env.PUBLIC_URL + 'basemap-plugin/esri-leaflet_orig.js'}
            onLoad={this.onEsriLeafletLoaded.bind(this)}
          />
          : null}

        {this.state.esriLeafletLoaded ?
          <Script
            key="esriVector"
            url={process.env.PUBLIC_URL + 'basemap-plugin/esri-leaflet-vector_orig.js'}
            onLoad={this.onEsriVectorLoaded.bind(this)}
          />
          : null
        }
        <Map
          className="mapDiv"
          zoom={this.state.prevMapZoom}
          center={this.state.prevMapCenter}
          ref='map'
          zoomControl={false}
          zoomSnap={0.1}
          zoomDelta={0.25}
          onZoomEnd={this.handleZoomEnd}
        >
          {this.props.points.map((point, idx) =>
            <Marker
              key={point.content.id + "_" + point.content.score + "_" + point.content.lat}
              position={point.position}
              icon={this.props.getIcon(point)}
              draggable={'true'}
              autoPan={false}
              onDragend={this.updateMarkerPosition.bind(this, point)}
              onClick={this.handleMapClick.bind(this, point)}
            >
            </Marker>
          )}
          <ZoomControl position="topright" />
        </Map>
        <CustomMapBtns
          basemap={this.state.basemap}
          updateBasemap={this.updateBasemap}
          updateMapState={this.updateMapState}
          currentZoom={this.state.currentZoom}
          updateMainObj={this.props.updateMainObj}
        />
      </div>
    );
  }
}

export default MapComponent;