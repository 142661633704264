import React, { useState } from 'react';
import Logo from './Logo';
import UserProfile from './UserProfile';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth0 } from '../../react-auth0-spa';

const titleStyleBold = {
  fontWeight: 'bold'
}

const whiteTitleArea = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#ffffff'
}

const titleArea = {
  height: '65px',
  display: 'flex',
  justifyContent: 'space-between',
  width: 'calc(100% - 100px)',
  alignItems: 'center'
}

const stackedTitleArea = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '0px',
  justifyContent: 'center'
}

function NavBar(props) {
  const [display, setDisplay] = useState('block');
  const { isAuthenticated, logout } = useAuth0();
  const link = props.link;
  const isMain = props.isMain;

  const openHamburgerMenu = () => {
    if (display === 'none') {
      setDisplay('block');
    } else {
      setDisplay('none');
    }
    document.getElementById('headerHamburgerMenuItemsDiv').style.display = display;
  }

  return (
    <div className="navBarDiv">
      <div className="topBlueBar"></div>
      <div style={whiteTitleArea}>
        <div className="blueIconArea">
          <Logo></Logo>
        </div>
        <div style={titleArea}>
          <div style={stackedTitleArea}>
            <div>
              <span><span style={titleStyleBold}>Geocoder</span></span>
            </div>
          </div>

          {isMain ?
            <div className="helpPanels">
              {/* <span title={linkTitle} className="helpPanelItem">
                <a className="helpLink" target="_blank" rel="noopener noreferrer" href={link}>
                  <svg height="28px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="#25408f" cx="50" cy="50" r="50" />
                    <text x="50%" y="50%" textAnchor="middle" fill="#ffffff" fontFamily="Open Sans" fontSize="75px" dy=".3em">?</text>
                  </svg>
                </a>
              </span> */}
            </div>
            :
            <div className="helpPanelsSupport">
              <span className="hydraLinkButton">
                <a className="hydraLink" href={link} target="_blank" rel="noopener noreferrer">
                  BOOM
              </a>
              </span>
            </div>
          }

          <UserProfile></UserProfile>
          <div className="headerHamburgerMenu">
            <div id="clickHeaderHamburger"><MenuIcon onClick={openHamburgerMenu}></MenuIcon></div>
          </div>
        </div>
      </div>
      <div id="headerHamburgerMenuItemsDiv">
        <div id="hamburgerFlex">
          <div className="headerHamItem borderNone"><a className="helpLinkMobile" href="mailto:colliers.gis@colliers.com">
            <span className="headerHamItemSpan">Feedback and support</span></a></div>
          {isAuthenticated ?
            <div className="headerHamItem helpLinkMobile" id="sign-out-mobile" onClick={() => logout({ returnTo: window.location.origin })}>Sign Out</div>
            : null
          }
        </div>
      </div>
    </div>
  );
}

export default NavBar;