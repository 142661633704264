import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class GeocodingSnackbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.updateMainObj({
      geocodingSuccess: false
    });
  }

  componentWillUnmount() {
    this.props.updateMainObj({
      geocodingSuccess: false
    });
  }

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.props.geocodingSuccess}
          autoHideDuration={5000}
          onClose={this.handleClose}
          message="Upload successful!"
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div >
    );
  }
}

export default GeocodingSnackbar;