import React from 'react';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';

class StartOverPanel extends React.Component {
  constructor(props) {
    super(props);
    this.startOver = this.startOver.bind(this);
  }

  startOver() {
    this.props.updateMainObj({
      isLoading: false,
      points: [],
      mapZoom: 5,
      mapCenter: [39.83, -98.58],
      geocodingSuccess: false,
      currentPointId: null,
      isGeocoding: false,
      isStartOver: true
    });
    this.props.togglePanel('startOver');
  }

  render() {
    return (
      <div className="sidePanelStyle">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <SVGIconComponent size={'16'} name={'startOverBtnIcon'} color={'#0c9ed9'} />
          </span>
          Start Over
        </h2>
        <div className="panel-body">
          <div>
            <div>Do you need to download your results?</div>
              <div className="flex margin-t15">
                <button className="geocodeBtn" onClick={this.props.download}>Yes</button>
                <button className="geocodeBtn" onClick={this.startOver}>No, start over</button>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

export default StartOverPanel;