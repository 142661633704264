import React from 'react';

class Support extends React.Component {
  render() {
    return (
      <div>
        Support Page
      </div>
    )
  }
}

export default Support;