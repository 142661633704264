import React from 'react';
import DownloadMenu from './DownloadMenu';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import { FormatResults } from '../../utils/tools';

class PointReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentRecord: null,
      pointArrayLength: null,
      candidates: null,
      isFindingAlternatives: false,
      isEditingRecord: false,
      editRecordText: ''
    }

    this.goToPrevious = this.goToPrevious.bind(this);
    this.goToNext = this.goToNext.bind(this);
  }

  componentDidMount() {
    const pointArrayLength = this.props.points.length;
    let thisPoint = this.props.points[0];
    if (this.props.currentPointId) {
      const thisPointArray = this.props.points.filter(point => point.content.id === this.props.currentPointId);
      thisPoint = thisPointArray[0];
    }
    const candidatesClone = Array.from(thisPoint.content.candidates);

    this.setState({
      currentRecord: thisPoint,
      pointArrayLength: pointArrayLength,
      candidates: candidatesClone
    });

    this.props.updateMainObj({
      mapZoom: 16,
      mapCenter: [thisPoint.content.lat, thisPoint.content.lng],
      currentPointId: thisPoint.content.id
    });
  }

  async componentDidUpdate() {
    if (this.state.currentRecord) {
      if (this.state.currentRecord.content.id !== this.props.currentPointId) {
        const newPoint = this.props.points.filter(point => point.content.id === this.props.currentPointId);

        this.setState({
          currentRecord: newPoint[0]
        });

        this.props.updateMainObj({ mapZoom: 16, mapCenter: [newPoint[0].content.lat, newPoint[0].content.lng] });
      }
    }
  }

  goToPrevious() {
    if (this.props.points[this.state.currentRecord.content.id - 2]) {
      const index = this.state.currentRecord.content.id - 2;
      const newPoint = this.props.points[index];

      this.setState({
        currentRecord: newPoint,
        editRecordText: '',
        isEditingRecord: false
      });

      this.props.updateMainObj({
        mapZoom: 16,
        mapCenter: [this.props.points[index].content.lat, this.props.points[index].content.lng],
        currentPointId: this.props.points[index].content.id
      });
    }
  }

  async goToNext() {
    if (this.props.points[this.state.currentRecord.content.id]) {
      var curid = this.state.currentRecord.content.id;

      var index = -1;
      for (var i = curid; i < this.props.points.length; i++) {
        if (this.props.points[i].content.score <= this.props.reviewScore) {
          index = i;
          break;
        }
      }
      if (index === -1)
        index = this.props.points.length - 1;

      if (index > -1) {
        const newPoint = this.props.points[index]; //since ID is 1 higher than index
        //console.log(this.props.reviewScore)
        ///get this stuff to fire on the first records
        if (newPoint.content.country.toLowerCase() === "united states" && newPoint.content.score <= this.props.reviewScore) {
          let adr = [];
          if (newPoint.content.isquickgeo) {
            adr.push({
              OBJECTID: 0,
              SingleLine: encodeURIComponent(newPoint.content.address),
              country: "UNITED STATES"
            });
          } else {
            adr.push({
              OBJECTID: 0,
              streetAddress: encodeURIComponent(newPoint.content.address),
              city: encodeURIComponent(newPoint.content.city),
              state: encodeURIComponent(newPoint.content.region),
              postalCode: encodeURIComponent(newPoint.content.postalcode),
              country: "UNITED STATES"
            });
          }

          const addresses = {
            addresses: adr,
            intl: false,
            skiplog: true
          }

          this.setState({
            isFindingAlternatives: true
          });

          await axios.post(process.env.REACT_APP_GEOCODERURL + '/geocode', addresses, { headers: { 'Authorization': 'Bearer ' + this.props.token } })
            .then((result) => {
              newPoint.content.candidates = result.data.addresses[0].output || [];
              this.setState({ isFindingAlternatives: false });
            }).catch((error) => {
              console.log(error);
              this.setState({ isFindingAlternatives: false });
            });
        }

        this.setState({
          currentRecord: newPoint,
          editRecordText: '',
          isEditingRecord: false
        });

        this.props.updateMainObj({
          mapZoom: 16,
          mapCenter: [this.props.points[index].content.lat, this.props.points[index].content.lng],
          currentPointId: this.props.points[index].content.id
        });
      }
    }
  }

  getCandidates(record) {
    if (record) {
      const candidatesClone = Array.from(record.content.candidates);
      return candidatesClone;
    }

    return [];
  }

  useAlternative(candidate, parentRecord) {
    const pointsArrayClone = Array.from(this.props.points);
    let thisPoint = null;
    //pointsArrayClone
    for (const point of pointsArrayClone) {
      if (point.content.id === parentRecord.content.id) {
        thisPoint = point;
        point.position.lat = candidate.location.y;
        point.position.lng = candidate.location.x;
        point.content.lat = candidate.location.y;
        point.content.lng = candidate.location.x;
        point.content.score = candidate.attributes.Score;
        point.content.type = candidate.attributes.Addr_type;
        point.content.icon = this.props.getIcon(point);

        //make all selected attributes false
        for (const thisCandidate of point.content.candidates) {
          if (thisCandidate.location.y === candidate.location.y && thisCandidate.location.x === candidate.location.x &&
            thisCandidate.attributes.Score === candidate.attributes.Score && thisCandidate.attributes.Addr_type === candidate.attributes.Addr_type
            && thisCandidate.address === candidate.address) {
            thisCandidate.selected = true;
          } else {
            thisCandidate.selected = false;
          }
        }
      }
    }

    this.props.updateMainObj({ points: pointsArrayClone, mapZoom: 16, mapCenter: [thisPoint.content.lat, thisPoint.content.lng] });
  }

  toggleEditRecord(bool) {
    this.setState({ isEditingRecord: bool });
  }

  onEditInputAddressChange(event) {
    let newText = event.target.value;
    this.setState({
      editRecordText: newText
    });
  }

  async runEditRecord() {
    if (this.state.editRecordText.trim() !== '') {
      const adr = [{
        OBJECTID: (this.state.currentRecord.content.id - 1) || 0,
        SingleLine: encodeURIComponent(this.state.editRecordText),
        country: "UNITED STATES"
      }];
      const addresses = {
        addresses: adr,
        intl: false,
        skiplog: true
      };

      this.setState({ isFindingAlternatives: true });

      await axios.post(process.env.REACT_APP_GEOCODERURL + '/geocode', addresses, { headers: { 'Authorization': 'Bearer ' + this.props.token } })
        .then((result) => {
          if (result.data) {
            if (result.data.addresses) {
              let formattedResults = FormatResults(result.data.addresses, true);
              let pointsArrayClone = Array.from(this.props.points);
              let newPointsArray = pointsArrayClone.map(obj => formattedResults.find(o => o.content.id === obj.content.id) || obj);

              this.props.updateMainObj({ points: newPointsArray, mapZoom: 16, mapCenter: [formattedResults[0].content.lat, formattedResults[0].content.lng] });
              this.setState({ isFindingAlternatives: false, currentRecord: formattedResults[0] });
            }
          }
        }).catch((error) => {
          console.log(error);
          this.setState({ isFindingAlternatives: false });
        });
    } else {
      return;
    }
  }

  render() {
    const isNextDisabled = this.state.currentRecord ? (this.state.currentRecord.content.id < this.state.pointArrayLength ? false : true) : true;
    const isPrevDisabled = this.state.currentRecord ? (this.state.currentRecord.content.id === 1 ? true : false) : true;
    const currentCandidates = this.getCandidates(this.state.currentRecord);

    return (
      <div>
        {this.state.isFindingAlternatives ?
          <div className="panel-loader"></div>
          :
          <div className="padding-t15">
            <div className="resultTableDiv">
              <div className="circleIdDiv">
                <div className="circle">
                  <div className="circleRecordId">{this.state.currentRecord ? this.state.currentRecord.content.id : ''}</div>
                </div>
                <div className="inputAddrDiv">
                  <div className="resultsTopDiv">
                    <div className="inputAddrText">Input address:</div>
                    <div className="padding-l20">
                      {!this.state.isEditingRecord ?
                        <button className="geocodeBtnSmall" onClick={this.toggleEditRecord.bind(this, true)}>Edit</button>
                        :
                        <div className="resultsTopDiv">
                          <div><button className="geocodeBtnSmall" onClick={this.toggleEditRecord.bind(this, false)}>Cancel</button></div>
                          <div className="padding-l10"><button className="geocodeBtnSmall" onClick={this.runEditRecord.bind(this, false)}>Run</button></div>
                        </div>
                      }
                    </div>
                  </div>
                  {!this.state.isEditingRecord ?
                    <div className="currAddrText">{this.state.currentRecord ? this.state.currentRecord.content.address : null}</div>
                    :
                    <TextField
                      id="editInputAddress"
                      multiline
                      variant="outlined"
                      defaultValue={this.state.currentRecord ? this.state.currentRecord.content.address : ''}
                      style={{ width: '100%'}}
                      onChange={(event) => this.onEditInputAddressChange(event)}
                    />
                  }
                </div>
              </div>
              <div className="inputAddrText padding-t10">Matches:</div>
              <div className="matchSection">
                {currentCandidates.length > 0 ? currentCandidates.map((result, i) => (
                  <div
                    key={"result" + i}
                    className={result.selected ? 'matchDivOuter blueBorder' : 'matchDivOuter grayBorder'}
                    onClick={this.useAlternative.bind(this, result, this.state.currentRecord)}
                  >
                    <div
                      className="matchAddrDiv"
                    >{result.address}</div>
                    <div className="scoreTypeDiv">
                      <div className="matchScore">
                        <span className="inputAddrText">Score: </span>
                        <span className="scoreTypeText">{result.attributes.Score}</span>
                      </div>
                      <div className="matchType padding-l10">
                        <span className="inputAddrText">Type: </span>
                        <span className="scoreTypeText">{result.attributes.Addr_type}</span>
                      </div>
                    </div>
                  </div>
                ))
                  : <div>No matches.</div>
                }
              </div>
            </div>
            <div className="buttonRow">
              <button className="geocodeBtn" onClick={this.props.backToMain}>Back</button>
              <button className="geocodeBtn" disabled={isPrevDisabled} onClick={this.goToPrevious}>Previous</button>
              <button className="geocodeBtn" disabled={isNextDisabled} onClick={this.goToNext}>Next</button>
              <DownloadMenu
                points={this.props.points}
              />
            </div>
          </div>
        }
      </div>
    )
  }
}

export default PointReview;