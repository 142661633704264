import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';

class InfoPanel extends React.Component {
  constructor(props) {
    super(props);

    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.props.togglePanel('info');
  }

  render() {
    return (
      <div className="sidePanelStyle infoZIndex">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <SVGIconComponent size={'16'} name={'infoBtnIcon'} color={'#0c9ed9'} />
          </span>
          Info
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div className="panel-body-small-text">Colliers Geocoder is built on ESRI's ArcGIS World Geocoding Service.  For full documentation of the ESRI service, please see:</div>
          <ul className="infoPanelUL">
            <li className="infoPanelLI">
              <a
                href="https://developers.arcgis.com/rest/geocode/api-reference/overview-world-geocoding-service.htm"
                target="_blank"
                rel="noopener noreferrer"
                className="infoLink"
              >ESRI Geocoding Overview
              </a>
            </li>
            <li className="infoPanelLI">
              <a
                href="https://developers.arcgis.com/rest/geocode/api-reference/geocode-coverage.htm"
                target="_blank"
                rel="noopener noreferrer"
                className="infoLink"
              >ESRI Geocoding Coverage
              </a>
            </li>
            <li className="infoPanelLI">
              <a
                href="https://developers.arcgis.com/rest/geocode/api-reference/geocoding-service-output.htm"
                target="_blank"
                rel="noopener noreferrer"
                className="infoLink"
              >ESRI Geocoding Service Output
              </a>
            </li>
          </ul>
          <div className="panel-body-small-text padding-t15">
            Below is a brief overview of some terms from the ESRI documentation that appear in the results panel.  For a more detailed explanation, please see the links above.
          </div>
          <div className="panel-body-small-text">
            <span className="boldSpan">Type: </span>Based on ESRI's Addr_type attribute, the type field "can be used as an indicator of the precision of geocode results."  The most precise level is PointAddress, which is generally a parcel or rooftop match.  Next is a StreetAddress, which denotes a street level or street entrance match.  More general matches are possible, such as a Postal match.  Postal matches the input address to the centroid of a postal code.
          </div>
          <div className="panel-body-small-text">
            <span className="boldSpan">Score: </span>Score is a number returned by ESRI's service that estimates the accuracy of the geocode.  Scores are between 1 and 100, where 100 indicates a perfect match.
          </div>
        </div>
      </div>
    );
  }
}

export default InfoPanel;