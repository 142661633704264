import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import ExposureNeg2Icon from '@material-ui/icons/ExposureNeg2';
import ExposurePlus2Icon from '@material-ui/icons/ExposurePlus2';

class CustomMapBtns extends React.Component {
  constructor(props) {
    super(props);

    this.toggleBasemap = this.toggleBasemap.bind(this);
    this.zoomToAll = this.zoomToAll.bind(this);
  }

  quickZoom(type) {
    const currentZoom = this.props.currentZoom;
    let newZoom = 0;

    if (type === 'zoomIn') {
      if (currentZoom < 18) {
        newZoom = currentZoom + 2;
      } else {
        newZoom = 20;
      }
    } else {
      if (currentZoom > 2) {
        newZoom = currentZoom - 2;
      } else {
        newZoom = 0;
      }
    }

    this.props.updateMainObj({ mapZoom: newZoom });
  }

  toggleBasemap() {
    if (this.props.basemap === 'colliers-grey') {
      this.props.updateBasemap('hybrid');
    } else {
      this.props.updateBasemap('colliers-grey');
    }
  }

  zoomToAll() {
    this.props.updateMapState({
      zoomToAll: true
    });
  }

  render() {
    const basemapBtnImageClass = this.props.basemap === 'hybrid' ? 'mapCustomBtnImageS' : 'mapCustomBtnImageH'
    return (
      <div>
        <div className="mapSideButtonsDiv">
          <Tooltip title="Toggle basemap" placement="left">
            <div className={basemapBtnImageClass} onClick={this.toggleBasemap}></div>
          </Tooltip>
        </div>
        <div className="mapSideButtonsDiv2">
          <div
            className="mapCustomBtn mapTopBtn"
            onClick={this.quickZoom.bind(this, 'zoomIn')}
          >
            <Tooltip title="Quick zoom in" placement="left">
              <ExposurePlus2Icon style={{ fontSize: '20px' }} />
            </Tooltip>
          </div>
          <div className="mapCustomBtn" onClick={this.zoomToAll}>
            <Tooltip title="Zoom to all points" placement="left">
              <ScatterPlotIcon style={{ fontSize: '24px' }} />
            </Tooltip>
          </div>
          <div
            className="mapCustomBtn mapBottomBtn"
            onClick={this.quickZoom.bind(this, 'zoomOut')}
          >
            <Tooltip title="Quick zoom out" placement="left">
              <ExposureNeg2Icon style={{ fontSize: '20px' }} />
            </Tooltip>
          </div>
        </div>

      </div>
    );
  }
}

export default CustomMapBtns;