import React from 'react';
import PointReview from './PointReview';
import DownloadMenu from './DownloadMenu';
import TextField from '@material-ui/core/TextField';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';
import axios from 'axios';

class ReviewResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewMainShow: true,
      pointReviewShow: false,
      pointsToReview: [],
      prevPointId: null,
      goToRecord: 1,
      reviewScore: 99.9
    }

    this.getResults = this.getResults.bind(this);
    this.startReview = this.startReview.bind(this);
    this.backToMain = this.backToMain.bind(this);
    this.goToRecord = this.goToRecord.bind(this);
  }

  componentDidUpdate() {
    if (this.state.prevPointId !== this.props.currentPointId) {
      this.setState({
        pointReviewShow: true,
        prevPointId: this.props.currentPointId,
        reviewMainShow: false
      });
    }
  }

  getResults(matchType) {
    const pointsArrayClone = Array.from(this.props.points);
    const get100s = pointsArrayClone.filter(point => point.content.score === 100);
    const get95s = pointsArrayClone.filter(point => (point.content.score < 100 && point.content.score >= 95));
    const get90s = pointsArrayClone.filter(point => (point.content.score < 95));
    const getCustom = pointsArrayClone.filter(point => point.content.score === 999);
    if (matchType === '100') {
      return get100s.length;
    } else if (matchType === '95') {
      return get95s.length;
    } else if (matchType === '90') {
      return get90s.length;
    } else if (matchType === 'custom') {
      return getCustom.length;
    } else {
      return 0;
    }
  }

  async startReview(type) {
    if (type === 'all') {
      this.setState({
        reviewScore: 100
      });
    }

    let startPoint = this.props.points[0];

    if (startPoint.content.country.toLowerCase() === "united states" && startPoint.content.score <= this.state.reviewScore) {
      let adr = [];
      if (startPoint.content.isquickgeo) {
        adr.push({
          OBJECTID: 0,
          SingleLine: encodeURIComponent(startPoint.content.address),
          country: "UNITED STATES"
        });
      } else {
        adr.push({
          OBJECTID: 0,
          streetAddress: encodeURIComponent(startPoint.content.address),
          city: encodeURIComponent(startPoint.content.city),
          state: encodeURIComponent(startPoint.content.region),
          postalCode: encodeURIComponent(startPoint.content.postalcode),
          country: "UNITED STATES"
        });
      }

      const addresses = {
        addresses: adr,
        intl: false,
        skiplog: true
      }

      await axios.post(process.env.REACT_APP_GEOCODERURL + '/geocode', addresses, {headers: { 'Authorization': 'Bearer ' + this.props.token }})
        .then((result) => {
          startPoint.content.candidates = result.data.addresses[0].output;
      });
    }

    this.setState({
      reviewMainShow: false,
      pointReviewShow: true
    });
  }

  backToMain() {
    this.setState({
      reviewMainShow: true,
      pointReviewShow: false,
      prevPointId: null,
      reviewScore: 99.9
    });
    this.props.updateMainObj({ currentPointId: null });
  }

  goToRecord() {
    this.props.updateMainObj({ currentPointId: this.state.goToRecord });
  }

  setGoToRecordValue(value) {
    let userVal = value || 1;
    if (value < 1) {
      userVal = 1;
    } else if (value > this.props.points.length) {
      userVal = this.props.points.length;
    }

    const num = Math.round(userVal);
    this.setState({
      goToRecord: num
    });
  }

  render() {
    return (
      <div>
        <div className="resultsTopDiv">
          <div>
            <div className="legendRowDiv">
              <div className="legendSVGIconDiv">
                <SVGIconComponent name="circleIcon" accuracy={100} label={''}/>
              </div>
              <div className="legendRowTextDiv">
                100% Match: <span className="resultTxtSpan">{this.getResults('100')}</span>
              </div>
            </div>
            <div className="legendRowDiv">
              <div className="legendSVGIconDiv">
                <SVGIconComponent name="circleIcon" accuracy={95} label={''}/>
              </div>
              <div className="legendRowTextDiv">
                95 - 99.9% Match: <span className="resultTxtSpan">{this.getResults('95')}</span>
              </div>
            </div>
            <div className="legendRowDiv">
              <div className="legendSVGIconDiv">
                <SVGIconComponent name="circleIcon" accuracy={90} label={''}/>
              </div>
              <div className="legendRowTextDiv">
                &lt; 95% Match: <span className="resultTxtSpan">{this.getResults('90')}</span>
              </div>
            </div>
            <div className="legendRowDiv">
              <div className="legendSVGIconDiv">
                <SVGIconComponent name="circleIcon" accuracy={999} label={''}/>
              </div>
              <div className="legendRowTextDiv">
                Custom location: <span className="resultTxtSpan">{this.getResults('custom')}</span>
              </div>
            </div>
          </div>
          <div className="goToRecordDiv">
            <div>Go to record:</div>
            <div className="goToRecordInnerDiv">
              <div>
                <TextField
                  id={"goToRecordInput"}
                  type="number"
                  defaultValue={1}
                  variant="outlined"
                  size="small"
                  style={{ width: '100px' }}
                  InputProps={{ inputProps: { min: 1, max: this.props.points.length, step: 1 } }}
                  onChange={(e) => { this.setGoToRecordValue(e.target.value) }}
                />
              </div>
              <div><button className="geocodeBtn" onClick={this.goToRecord}>Go</button></div>
            </div>
          </div>
        </div>
        {this.state.reviewMainShow ?
          <div>
            <div className="resultInstructions paddingT10">
              To download the results, click the download button below.  If you would like to review and refine the results, either click to review all or review only results with a less than perfect score.  Additionally, you can click any point on the map to edit individual results.
            </div>
            <div className="buttonRow">
              <button className="geocodeBtn" onClick={this.startReview.bind(this, 'all')}>Review All</button>
              {this.props.points.length > this.getResults('100') ?
                <button className="geocodeBtn" onClick={this.startReview.bind(this, 'some')}>Review &#60; 100</button>
                : null
              }
              {/* <button className="geocodeBtn" onClick={this.startReview.bind(this, 'some')}>Review Non-rooftop</button> */}
              <DownloadMenu
                points={this.props.points}
                useIntl={this.props.useIntl}
                countryColName={this.props.countryColName}
              />
            </div>
          </div>
          : null
        }
        {this.state.pointReviewShow ?
          <PointReview
            token={this.props.token}
            useIntl={this.props.useIntl}
            points={this.props.points}
            pointsToReview={this.state.pointsToReview}
            updateMainObj={this.props.updateMainObj}
            getIcon={this.props.getIcon}
            currentPointId={this.props.currentPointId}
            backToMain={this.backToMain}
            reviewScore={this.state.reviewScore}
          />
          : null
        }
      </div>
    )
  }
}

export default ReviewResults;