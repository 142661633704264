import React from 'react';
import '../App.css';
import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';
import MapComponent from './MapComponent/MapComponent';
import GeocodingSnackbar from './MapComponent/GeocodingSnackbar';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import SVGIconComponent from './SVGIconComponent/SVGIconComponent';
import axios from 'axios';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      points: [],
      mapZoom: 5,
      mapCenter: [39.83, -98.58],
      geocodingSuccess: false,
      token: null,
      currentPointId: null,
      isGeocoding: false,
      isStartOver: false,
      validUser: false
    };

    this.updateMainObj = this.updateMainObj.bind(this);
  }

  componentDidMount() {
    this.getUserPerm();
  }

  componentDidUpdate() {
    if (this.state.isStartOver) { //set start over back to false
      //setTimeout(() => {
        this.setState({
          isStartOver: false
        });
      //}, 100);
    }
  }

  async getUserPerm() {
    await this.getAuthToken();
    const validcos = (process.env.REACT_APP_VALIDCOS || 'US|CA|AUS').split('|');

    
    await axios.post(process.env.REACT_APP_BOOMAPI + '/getOfficeCoordsAndUserId', null,{headers: {'Authorization': 'Bearer ' + this.state.token }})
    .then((result) => {
        if(validcos.includes(result.data.country)){
          this.setState({validUser:true});
        }
        else{
          console.log('invalid');
        }
        const supergeo = result.data.supergeo || false;
        if(supergeo){
          this.setState({useIntl:true});
        }
      });

    await axios.post(process.env.REACT_APP_GEOCODERURL + '/getPermissions', null,{headers: {'Authorization': 'Bearer ' + this.state.token }})
    .then((result) => {
      if(result.data.access==="International")
        this.setState({useIntl:true});
      });
  }

  async getAuthToken() {
    await this.props.getTokenSilently()
    .then((result) => {
      localStorage.setItem('access_token', result);
      //apiToken = localStorage.getItem('access_token');
      this.setState({ token: result});
    }).catch((error) => {
      console.log(error);
    });
  }

  updateMainObj(mainObj) {
    this.setState(mainObj);
  }

  getIcon(point) {
    let icon = L.divIcon({
      className: 'custom-icon',
      html: ReactDOMServer.renderToString(<SVGIconComponent label={point.content.id} name={'circleIcon'} accuracy={point.content.score} />)
    });

    return icon;
  }

  render() {
    var loadingClass = this.state.isLoading ? 'loaderShow' : 'loaderHide';
    var mainClass = this.state.isLoading ? 'loaderHide' : 'loaderShowMain';
    return (
      <div className="appDiv">
        <div className={loadingClass}>
          <div className="loader">
          </div>
        </div>
        {this.state.validUser ?
        <div className={mainClass}>
          <NavBar
            link={'/support'}
            linkTitle={'Geocoder Support Center'}
            isMain={true}
          >
          </NavBar>
          
          <div className="pageContainer">
            <SideBar
              token={this.state.token}
              updateMainObj={this.updateMainObj}
              points={this.state.points}
              getIcon={this.getIcon}
              currentPointId={this.state.currentPointId}
              isGeocoding={this.state.isGeocoding}
              isStartOver={this.state.isStartOver}
              useIntl={this.state.useIntl}
            ></SideBar>
            <MapComponent
              points={this.state.points}
              mapCenter={this.state.mapCenter}
              mapZoom={this.state.mapZoom}
              geocodingSuccess={this.state.geocodingSuccess}
              updateMainObj={this.updateMainObj}
              getIcon={this.getIcon}
              isStartOver={this.state.isStartOver}
            ></MapComponent>
          </div>
          <GeocodingSnackbar
            geocodingSuccess={this.state.geocodingSuccess}
            updateMainObj={this.updateMainObj}
          />
        </div>
        : null }
      </div>
    );
  }
}

export default App;