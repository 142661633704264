import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SVGIconComponent from '../SVGIconComponent/SVGIconComponent';

class TroubleShootPanel extends React.Component {
  constructor(props) {
    super(props);

    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.props.togglePanel('troubleShoot');
  }

  render() {
    return (
      <div className="sidePanelStyle infoZIndex">
        <h2 className="panel-title">
          <span className="panel-icon-span">
            <SVGIconComponent size={'16'} name={'toolsBtnIcon'} color={'#0c9ed9'} />
          </span>
          Troubleshooting / Best Practices
          <span className="panel-close closeStyle"><CloseIcon onClick={this.closePanel} fontSize="small" /></span>
        </h2>
        <div className="panel-body">
          <div className="panel-body-small-text">For best results, use the following format when uploading a spreadsheet (column names are up to the user and will not effect geocoding results).  Include all necessary rows, depending on the level of precision needed.  Avoid spelling errors, extra text or symbols, comments or notes, and missing data.</div>
          <div className="ts-tableBody">
              <div className="ts-headerRow">
                <div className="ts-row1 ts-border">Address/Intersection</div>
                <div className="ts-row2 ts-border">City/Town</div>
                <div className="ts-row3 ts-border">State/Province</div>
                <div className="ts-row4 ts-border">ZIP/Postal</div>
                <div className="ts-row5 ts-border3">Country</div>
              </div>
              <div className="ts-dataRow">
                <div className="ts-row1 ts-border2">123 Fake St</div>
                <div className="ts-row2 ts-border2">New York</div>
                <div className="ts-row3 ts-border2">NY</div>
                <div className="ts-row4 ts-border2">01010</div>
                <div className="ts-row5 ts-border4"></div>
              </div>
              <div className="ts-dataRow">
                <div className="ts-row1 ts-border2">123 Fake St</div>
                <div className="ts-row2 ts-border2">Guadalajara</div>
                <div className="ts-row3 ts-border2">Jalisco</div>
                <div className="ts-row4 ts-border2"></div>
                <div className="ts-row5 ts-border4">Mexico</div>
              </div>
              <div className="ts-dataRow">
                <div className="ts-row1 ts-border2">Fake St &amp; Pretend Blvd</div>
                <div className="ts-row2 ts-border2">New York</div>
                <div className="ts-row3 ts-border2">NY</div>
                <div className="ts-row4 ts-border2"></div>
                <div className="ts-row5 ts-border4">US</div>
              </div>
          </div>
          <div className="panel-body-small-text padding-t15">
            If all addresses are in the US, select <span className="boldSpan">US Only</span> from the dropdown for the country field. City and state <span className="boldSpan">or</span> ZIP/postal code must be included.
          </div>
          <div className="ts-faq-text">
            <span className="boldSpan">What to do if...</span>
          </div>
          <div className="panel-body-small-text">
            <span className="boldSpan">Geocoder results are to the city, state, or ZIP level even though addresses were provided.</span>
          </div>
          <div className="panel-body-small-text">
            Check address field for spelling errors or bad addresses.  Avoid ambigous notes that might help a person find the area but are not a part of the address.  For example, for a land lot, use the intersection (Fake St &amp; Pretend Blvd), but avoid extra wording (Fake St &amp; Pretend Blvd - right side of lot).
          </div>
          <div className="panel-body-small-text">
            While some points of interest can be geocoded, for most accurate results provide an address or intersection.  Building names like "Liberty Center" or points of interest like "DFW" may not geocode correctly.
          </div>
          <div className="panel-body-small-text">
            <span className="boldSpan">Geocoder results are in the middle of the ocean or another location very far from expected.</span>
          </div>
          <div className="panel-body-small-text">
            If results are in the ocean or far from the targeted area, there is problem with the ZIP/postal code, state, and/or city.  Check for spelling errors and extra text, like notes, that are not part of the legal address.  For example, "Tri-state Area", "Midland/Odessa", "Colorado border" are not valid entries for city or state. If your postal code begins with a 0, check to see that the leading 0 has not been truncated. 
          </div>
        </div>
      </div>
    );
  }
}

export default TroubleShootPanel;