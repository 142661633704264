import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class MapColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadError: false
    }
    
    this.uploadGeo = this.uploadGeo.bind(this);
  }

  async uploadGeo() {
    let countryRef = '';
    if(this.props.useIntl)
     countryRef = this.countryRef.value;

    if (this.postalRef.value === "None") {
      if (countryRef === "") {
        if (this.stateRef.value === "None" || this.cityRef.value === "None") {
          this.setState({
            uploadError: true
          });
          return;
        }
      } else {
        if (this.cityRef.value === "None") {
          this.setState({
            uploadError: true
          });
          return;
        }
      }
    }

    if (this.stateRef.value === "None" || this.cityRef.value === "None") {
      if (countryRef === "") {
        if (this.postalRef.value === "None") {
          this.setState({
            uploadError: true
          });
          return;
        }
      } else {
        if (this.cityRef.value === "None" && this.postalRef.value === "None") {
          this.setState({
            uploadError: true
          });
          return;
        }
      }
    }

    const togglePanel = await this.props.geocode(this.props.data, false, this.addressRef.value, this.cityRef.value, this.stateRef.value, this.postalRef.value, countryRef, this.props.useIntl);
    if (togglePanel) {
      this.props.togglePanels(false, false, true);
    }
  }

  selectChange(colName, event) {
    this.setState({
      uploadError: false
    });
    this.props.mapColumnNames(colName, event.target.value);
  }

  render() {
    var loadingClass = this.props.geocodeLoading ? 'loaderShow' : 'loaderHide';
    var mainClass = this.props.geocodeLoading ? 'loaderHide' : 'loaderShow';
    return (
      <div>
        <div className={loadingClass}>
          <div className="panel-loader"></div>
        </div>
        <div className={mainClass}>
          <div className="panel-body-small-text">
            Select the correct column headers from your data in each of the dropdowns below.  If the fields in the dropdown are already correct, click Geocode to continue.
          </div>
          <div className="margin-t15 margin-b5">
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Address:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="address-select"
                  id="address-select"
                  key={"addressKey"}
                  value={this.props.addressColName}
                  onChange={(event) => this.selectChange('addressColName', event)}
                  inputRef={ref => { this.addressRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">City:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="city-select"
                  id="city-select"
                  value={this.props.cityColName}
                  onChange={(event) => this.selectChange('cityColName', event)}
                  key={"cityKey"}
                  inputRef={ref => { this.cityRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">State:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="state-select"
                  id="state-select"
                  value={this.props.stateColName}
                  onChange={(event) => this.selectChange('stateColName', event)}
                  key={"stateKey"}
                  inputRef={ref => { this.stateRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Postal Code:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="postal-select"
                  id="postal-select"
                  value={this.props.postalColName}
                  onChange={(event) => this.selectChange('postalColName', event)}
                  key={"postalKey"}
                  inputRef={ref => { this.postalRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'None'}
                  key={'none'}
                >None</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            {this.props.useIntl?
            <div className="targetLayerOuterDiv">
              <div className="targetLayerLabel">Country:</div>
              <div className="targetLayerSelectDiv">
                <Select
                  labelId="county-select"
                  id="country-select"
                  value={this.props.countryColName}
                  onChange={(event) => this.selectChange('countryColName', event)}
                  key={"countryKey"}
                  inputRef={ref => { this.countryRef = ref; }}
                  variant="outlined"
                ><MenuItem
                  value={'USOnly'}
                  key={'usonly'}
                >US Only</MenuItem>
                  {
                    this.props.columns.map((col, i) => (
                      <MenuItem
                        value={col}
                        key={col + "_" + i}
                      >{col}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>:null}
          </div>
          <div className="buttonRow">
            <button className="geocodeBtn" onClick={this.props.togglePanels.bind(this, true, false, false)}>Back</button>
            <button className="geocodeBtn" onClick={this.uploadGeo}>Geocode</button>
          </div>
          {this.props.geocodeError || this.state.uploadError ?
            <div className="errorMsgDiv">
              {this.state.uploadError ? "Must select Postal Code or City/State." : "Oops!  Something went wrong.  Please try again."}
            </div>
            : null}
        </div>
      </div>
    );
  }
}

export default MapColumns;